<template>
  <vs-breadcrumb class="w-full">
    <li>
      <h1 class="mt-2 pr-4 mr-2" >
        {{ title }}
      </h1>
    </li>
    <li class="mt-3" >
      <router-link to="/redirect-to-home" class="mt-2">
        <i class="onpoint-house" style="font-size:24px;margin-left:40px;"></i>
      </router-link>
      <span class="vs-breadcrumb--separator">
        <i class="onpoint-caret-right"></i>        
      </span>
    </li>
    <li class="mt-2" v-if="previousPage">
      <router-link :to="`/${previousPage}`">
        <span class="mt-2 font-normal text-sm md:text-lg">{{
          previousPageTitle
        }}</span>
      </router-link>
      <span class="vs-breadcrumb--separator">
        <span class="material-icons mt-2">chevron_right</span>
      </span>
    </li>
    <li aria-current="page" class="mt-2 active font-normal text-sm md:text-lg">
      {{ actualPage }}
    </li>
  </vs-breadcrumb>
</template>

<script>
import onPointIcon from "@/components/onPointIcons";
export default {
  name: "breadcrumb",
  components: {
    onPointIcon
  },
  props: ["title", "actualPage", "previousPage", "previousPageTitle"]
};
</script>

<style lang="scss">
.vs-breadcrumb h1 {
  border-right: solid 1px rgba(0, 0, 0, 0.2);
}

.vs-breadcrumb--ol {
  align-items: center;
}

.vs-breadcrumb--ol li {
  line-height: 24px;
}

.vs-breadcrumb--ol li > a,
.vs-breadcrumb--ol li > span {
  vertical-align: middle;
}
</style>
