var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vs-breadcrumb", { staticClass: "w-full" }, [
    _c("li", [
      _c("h1", { staticClass: "mt-2 pr-4 mr-2" }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
      ]),
    ]),
    _c(
      "li",
      { staticClass: "mt-3" },
      [
        _c(
          "router-link",
          { staticClass: "mt-2", attrs: { to: "/redirect-to-home" } },
          [
            _c("i", {
              staticClass: "onpoint-house",
              staticStyle: { "font-size": "24px", "margin-left": "40px" },
            }),
          ]
        ),
        _c("span", { staticClass: "vs-breadcrumb--separator" }, [
          _c("i", { staticClass: "onpoint-caret-right" }),
        ]),
      ],
      1
    ),
    _vm.previousPage
      ? _c(
          "li",
          { staticClass: "mt-2" },
          [
            _c("router-link", { attrs: { to: `/${_vm.previousPage}` } }, [
              _c(
                "span",
                { staticClass: "mt-2 font-normal text-sm md:text-lg" },
                [_vm._v(_vm._s(_vm.previousPageTitle))]
              ),
            ]),
            _c("span", { staticClass: "vs-breadcrumb--separator" }, [
              _c("span", { staticClass: "material-icons mt-2" }, [
                _vm._v("chevron_right"),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
    _c(
      "li",
      {
        staticClass: "mt-2 active font-normal text-sm md:text-lg",
        attrs: { "aria-current": "page" },
      },
      [_vm._v("\n    " + _vm._s(_vm.actualPage) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }