<template>
  <div class="onPointIcon">
    <div class="icon" v-if="icon == 'copy'">
      <svg
        width="16"
        height="16"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 3.47C9.49479 3.42407 9.48474 3.37882 9.47 3.335V3.29C9.44596 3.23859 9.41389 3.19133 9.375 3.15L6.375 0.15C6.33367 0.111108 6.28641 0.0790405 6.235 0.0549999C6.22008 0.05288 6.20493 0.05288 6.19 0.0549999C6.13921 0.0258706 6.08311 0.0071721 6.025 0H4C3.60218 0 3.22064 0.158035 2.93934 0.43934C2.65804 0.720644 2.5 1.10218 2.5 1.5V2H2C1.60218 2 1.22064 2.15804 0.93934 2.43934C0.658035 2.72064 0.5 3.10218 0.5 3.5V8.5C0.5 8.89782 0.658035 9.27936 0.93934 9.56066C1.22064 9.84196 1.60218 10 2 10H6C6.39782 10 6.77936 9.84196 7.06066 9.56066C7.34196 9.27936 7.5 8.89782 7.5 8.5V8H8C8.39782 8 8.77936 7.84196 9.06066 7.56066C9.34196 7.27936 9.5 6.89782 9.5 6.5V3.5C9.5 3.5 9.5 3.5 9.5 3.47ZM6.5 1.705L7.795 3H7C6.86739 3 6.74021 2.94732 6.64645 2.85355C6.55268 2.75979 6.5 2.63261 6.5 2.5V1.705ZM6.5 8.5C6.5 8.63261 6.44732 8.75979 6.35355 8.85355C6.25979 8.94732 6.13261 9 6 9H2C1.86739 9 1.74021 8.94732 1.64645 8.85355C1.55268 8.75979 1.5 8.63261 1.5 8.5V3.5C1.5 3.36739 1.55268 3.24021 1.64645 3.14645C1.74021 3.05268 1.86739 3 2 3H2.5V6.5C2.5 6.89782 2.65804 7.27936 2.93934 7.56066C3.22064 7.84196 3.60218 8 4 8H6.5V8.5ZM8.5 6.5C8.5 6.63261 8.44732 6.75979 8.35355 6.85355C8.25979 6.94732 8.13261 7 8 7H4C3.86739 7 3.74021 6.94732 3.64645 6.85355C3.55268 6.75979 3.5 6.63261 3.5 6.5V1.5C3.5 1.36739 3.55268 1.24021 3.64645 1.14645C3.74021 1.05268 3.86739 1 4 1H5.5V2.5C5.5 2.89782 5.65804 3.27936 5.93934 3.56066C6.22064 3.84196 6.60218 4 7 4H8.5V6.5Z"
          fill="#50555A"
        />
      </svg>
    </div>
    <div class="icon" v-if="icon == 'home'" style="margin-left: 40px;">
      <svg
        width="24"
        height="24"
        viewBox="0 0 18 20"
        x="1px"
        y="2px"
        xml:space="preserve"
      >
        <path
          d="M14.6667 5.66667L9.66667 1.28334C9.20831 0.873376 8.61494 0.646729 8 0.646729C7.38505 0.646729 6.79168 0.873376 6.33333 1.28334L1.33333 5.66667C1.06864 5.9034 0.857416 6.1938 0.713725 6.51854C0.570035 6.84328 0.497176 7.19491 0.499999 7.55V14.8333C0.499999 15.4964 0.763391 16.1323 1.23223 16.6011C1.70107 17.0699 2.33696 17.3333 3 17.3333H13C13.663 17.3333 14.2989 17.0699 14.7678 16.6011C15.2366 16.1323 15.5 15.4964 15.5 14.8333V7.54167C15.5016 7.18797 15.4282 6.83795 15.2846 6.51474C15.1409 6.19152 14.9303 5.90247 14.6667 5.66667ZM9.66667 15.6667H6.33333V11.5C6.33333 11.279 6.42113 11.067 6.57741 10.9107C6.73369 10.7545 6.94565 10.6667 7.16667 10.6667H8.83333C9.05435 10.6667 9.26631 10.7545 9.42259 10.9107C9.57887 11.067 9.66667 11.279 9.66667 11.5V15.6667ZM13.8333 14.8333C13.8333 15.0544 13.7455 15.2663 13.5893 15.4226C13.433 15.5789 13.221 15.6667 13 15.6667H11.3333V11.5C11.3333 10.837 11.0699 10.2011 10.6011 9.73224C10.1323 9.2634 9.49637 9 8.83333 9H7.16667C6.50362 9 5.86774 9.2634 5.3989 9.73224C4.93006 10.2011 4.66667 10.837 4.66667 11.5V15.6667H3C2.77899 15.6667 2.56702 15.5789 2.41074 15.4226C2.25446 15.2663 2.16667 15.0544 2.16667 14.8333V7.54167C2.16682 7.42335 2.19216 7.30642 2.24101 7.19865C2.28987 7.09089 2.36111 6.99476 2.45 6.91667L7.45 2.54167C7.60207 2.40807 7.79757 2.33439 8 2.33439C8.20242 2.33439 8.39792 2.40807 8.55 2.54167L13.55 6.91667C13.6389 6.99476 13.7101 7.09089 13.759 7.19865C13.8078 7.30642 13.8332 7.42335 13.8333 7.54167V14.8333Z"
          fill="#50555A"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "onPointIcon",
  props: ["icon"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.onPointIcon {
  display: inline-block;
}
.icon {
  cursor: pointer;
  margin-top: 5px;
  
}
.icon svg:hover > path {
  fill: rgba(var(--vs-primary), 1) !important;
}
</style>
